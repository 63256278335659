import { graphql, useStaticQuery, Link } from 'gatsby'
import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

import { breakpoints } from '../utils/styles'

import SEO from '~/components/seo'

const NotFoundPage = () => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/404/404.jpg" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  console.log(image)

  return (
    <>
      <SEO title="404: Not found" />
      <Container>
        <Title>
          Devinez qui a gagné à cache-cache aujourd’hui? Cette page!
        </Title>
        <Image fixed={image.file.childImageSharp.fixed} />
        <Text>
          Il semble que vous ayez atterri sur une page qui n’est pas tout à fait
          prête ou n’est pas celle que vous cherchez.
        </Text>
        <Button to="/">Aller a la page d’accueil</Button>
        <Text>
          Encore besoin d’aide ?<Link to="/contact"> Contactez-nous</Link>
        </Text>
      </Container>
    </>
  )
}

export default NotFoundPage

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2em 0;
`

export const Image = styled(Img)``

export const Title = styled.p`
  color: #2b5c6b;
  font-size: 1.5em;
  font-weight: 400;
  text-align: center;
  padding: 0 1em;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.2em;
    display: block;
    padding: 1em;
  }
`

export const Text = styled.p`
  font-size: 1.1em;
  text-align: center;
  font-family: 'Avenir Light', sans-serif;
  padding: 0 1em;
`

export const Button = styled(Link)`
  background-color: #2b5c6b;
  color: #eed7b8;
  border: 2px solid transparent;
  border-radius: 30px;
  padding: 0.5em 1em;
  margin: 1em 0;
  text-decoration: none;

  :hover {
    cursor: pointer;
    background-color: transparent;
    border: 2px solid #2b5c6b;
    color: #2b5c6b;
  }
`
